import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import { FlowService } from '@/sevices';
import * as Utils from '@/common/utils';
import Perm from '@/router/permission';
let FlowExceptionDetail = class FlowExceptionDetail extends Vue {
    constructor() {
        super(...arguments);
        this.fixLoading = false;
        this.visible = false;
        this.flowExceptionDetail = {};
        this.statusMap = {
            NO_RECOVERED: '未修复',
            RECOVERED: '已修复',
        };
    }
    get isAdmin() {
        return Perm.isAdmin();
    }
    onClose() {
        this.$emit('input', false);
    }
    async getExceptionDetail() {
        const res = await FlowService.getExceptionDetail({ id: this.id });
        if (res.success) {
            this.flowExceptionDetail = res.data;
        }
    }
    fixExpLog() {
        this.fixLoading = true;
        const params = {
            exceptionLogId: this.id,
            fixNotes: this.flowExceptionDetail.fixNotes,
            appCode: this.flowExceptionDetail.appCode,
        };
        FlowService.fixExpLog(params).then(async (res) => {
            if (res.success) {
                await Utils.sleep(3000);
                this.$message.success('修复成功，列表数据更新如有延迟，请稍候手动刷新');
                this.onClose();
                this.$emit('reload');
            }
        }).catch((e) => {
            try {
                const error = JSON.parse(e);
                if (error.errCode === '100000') {
                    this.onClose();
                    this.$emit('reload');
                }
            }
            catch (err) {
                // do nothing
            }
        }).finally(() => {
            this.fixLoading = false;
        });
    }
    onValueChange(v) {
        this.visible = v;
        if (!this.visible) {
            this.flowExceptionDetail = {};
            return;
        }
        this.getExceptionDetail();
    }
};
__decorate([
    Prop()
], FlowExceptionDetail.prototype, "value", void 0);
__decorate([
    Prop()
], FlowExceptionDetail.prototype, "id", void 0);
__decorate([
    Watch('value')
], FlowExceptionDetail.prototype, "onValueChange", null);
FlowExceptionDetail = __decorate([
    Component({
        name: 'FlowExceptionDetail',
    })
], FlowExceptionDetail);
export default FlowExceptionDetail;
